import { ResponsiveBar } from '@nivo/bar'
import styles from './TopSellingItems.module.css'
import { getFormattedCurrency } from '../../utils'
import CustomEmptySecondary from '../custom-empty-secondary'
import { useEffect, useRef, useState } from 'react'
import { timeSpan } from '../../utils/constants'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Button, DatePicker, Dropdown, Menu, Skeleton, Space, Spin, Tooltip } from 'antd'
import { ReloadOutlined, RightOutlined, LeftOutlined, DownOutlined } from '@ant-design/icons'
import { useRouter } from 'next/router'
import { Products } from '../../services/api/products'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTopSellingProductsInTimeRange } from '../../store/dashboard/actions'

const dateFormatStringWithTime = 'MMM D, YYYY h:mm A'
const dateFormatString = 'MMM D, YYYY'

const productStatuses = [
	{
		label: 'By Requested',
		key: 'requested'
	},
	{
		label: 'By Approved',
		key: 'approved'
	},
	{
		label: 'By In-Transit',
		key: 'in_transit'
	},
	{
		label: 'By Delivered',
		key: 'completed'
	}
]

const timePeriods = [
	{
		label: 'Today\'s',
		value: timeSpan.today
	},
	{
		label: 'This Week',
		value: timeSpan.week
	},
	{
		label: 'This Month',
		value: timeSpan.month
	},
	{
		label: 'This Year',
		value: timeSpan.year
	},
	{
		label: 'Lifetime',
		value: timeSpan.lifetime
	},
	{
		label: 'Custom',
		value: timeSpan.custom
	}
]

const TopSellingItems = (
	isLoadingSalesAnalyticsOrderTrends
) => {
	const dispatch = useDispatch()
	const router = useRouter()
	const {	topSellingProductsInTimeRange } = useSelector(state => state.dashboardReducer)
	const [showGraphView, setShowGraphView] = useState(false)
	const [topProductsStatus, setTopProductsStatus] = useState(productStatuses[0])
	const [isLoadingProducts, setIsLoadingProducts] = useState(isLoadingSalesAnalyticsOrderTrends)
	const [topProductsDateRange, setTopProductsDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [isDownloadingProducts, setIsDownloadingProducts] = useState(false)

	const productsRef = useRef()

	useEffect(() => {
		getTopSellingProducts()
	}, [topProductsDateRange.fromDate, topProductsDateRange.toDate, topProductsStatus])

	const getTopSellingProducts = async () => {
		setIsLoadingProducts(true)
		const params = {
			status: topProductsStatus.key,
			variant: true
		}
		if (topProductsDateRange.fromDate && topProductsDateRange.toDate) {
			params.fromDate = topProductsDateRange.fromDate
			params.toDate = topProductsDateRange.toDate
		}
		await dispatch(fetchTopSellingProductsInTimeRange(params))
		setIsLoadingProducts(false)
	}

	const downloadTopSellingProductsCsv = async () => {
		if (isDownloadingProducts) {
			return
		}
		setIsDownloadingProducts(true)
		const params = {
			status: topProductsStatus.key,
			variant: true
		}
		if (topProductsDateRange.fromDate && topProductsDateRange.toDate) {
			params.fromDate = topProductsDateRange.fromDate
			params.toDate = topProductsDateRange.toDate
		}
		await Products.downloadTopSellerCsv(params)
		setIsDownloadingProducts(false)
	}


	const renderDateRange = (timeRange) => {
		return (
			<div className={styles.timeScheduleContainer}>
				{timeRange.timePeriod.value === timeSpan.lifetime && <span>Lifetime</span>}
				{
					(timeRange.timePeriod.value === timeSpan.today || timeRange.timePeriod.value === timeSpan.custom) &&
					<div>
						{moment(timeRange.fromDate).format(dateFormatStringWithTime)} - {moment(timeRange.toDate).format(dateFormatStringWithTime)}
					</div>
				}
				{
					(timeRange.timePeriod.value === timeSpan.week || timeRange.timePeriod.value === timeSpan.month || timeRange.timePeriod.value === timeSpan.year) &&
					<div>
						{moment(timeRange.fromDate).format(dateFormatString)} - {moment(timeRange.toDate).format(dateFormatString)}
					</div>
				}
			</div>
		)
	}

	const dateRangeChangeHandler = (setDateRange, key, value) => {
		setDateRange((previousValue) => ({ ...previousValue, [key] : value }))
	}

	const renderDatePicker = (dateRange, setDateRange) => {
		return (
			<Space size='small'>
				<DatePicker
					dropdownClassName={styles.overlay}
					placeholder='From'
					value={dateRange.fromDate ? moment(dateRange.fromDate) : null}
					onChange={(date) => dateRangeChangeHandler(setDateRange, 'fromDate', date?.toDate())}
					showTime={{
						use12Hours: true
					}}
					format='YYYY-MM-DD h:mm A'
				/>
				<div className={styles.dash} />
				<DatePicker
					dropdownClassName={styles.overlay}
					placeholder='To'
					value={dateRange.toDate ? moment(dateRange.toDate) : null}
					onChange={(date) => dateRangeChangeHandler(setDateRange, 'toDate', date?.toDate())}
					disabledDate={current => current && current.valueOf() < moment(dateRange.fromDate)}
					showTime={{
						use12Hours: true
					}}
					format='YYYY-MM-DD h:mm A'
				/>
			</Space>
		)
	}

	const sideScroll = (
		element,
		speed,
		distance,
		step
	) => {
		let scrollAmount = 0
		const slideTimer = setInterval(() => {
			element.scrollLeft += step
			scrollAmount += Math.abs(step)
			if (scrollAmount >= distance) {
				clearInterval(slideTimer)
			}
		}, speed)
	}

	const onTimePeriodSelected = (
		period,
		setPeriod
	) => {
		dateRangeChangeHandler(setPeriod, 'timePeriod', period)
		switch (period.value) {
			case 'weekly':
			case timeSpan.week: {
				const start = moment().startOf('week').toDate()
				const end = moment().endOf('week').toDate()
				dateRangeChangeHandler(setPeriod, 'fromDate', start)
				dateRangeChangeHandler(setPeriod, 'toDate', end)
				break
			}
			case 'monthly':
			case timeSpan.month: {
				const start = moment().startOf('month').toDate()
				const end = moment().endOf('month').toDate()
				dateRangeChangeHandler(setPeriod, 'fromDate', start)
				dateRangeChangeHandler(setPeriod, 'toDate', end)
				break
			}
			case 'yearly':
			case timeSpan.year: {
				const start = moment().startOf('year').toDate()
				const end = moment().endOf('year').toDate()
				dateRangeChangeHandler(setPeriod, 'fromDate', start)
				dateRangeChangeHandler(setPeriod, 'toDate', end)
				break
			}
			case 'lifetime':
			case timeSpan.lifetime: {
				dateRangeChangeHandler(setPeriod, 'fromDate', null)
				dateRangeChangeHandler(setPeriod, 'toDate', null)
				break
			}
			case timeSpan.custom:
				break
			case 'daily':
			case timeSpan.today:
			default: {
				const start = moment().startOf('day').toDate()
				const end = moment().endOf('day').toDate()
				dateRangeChangeHandler(setPeriod, 'fromDate', start)
				dateRangeChangeHandler(setPeriod, 'toDate', end)
				break
			}
		}
	}

	const renderTopProducts = () => {
		const productMap = {}
		topSellingProductsInTimeRange.forEach(product => {
			productMap[product.id] = product
		})
		return (
			showGraphView ?
				<div className={styles.productGraphContainer}>
					{
						topSellingProductsInTimeRange.length > 0 ?
							<ResponsiveBar
								keys={topSellingProductsInTimeRange.map(({ id }) => productMap[id].name)}
								legends={[
									{
										dataFrom: 'keys',
										anchor: 'bottom-right',
										direction: 'column',
										justify: false,
										translateX: 120,
										translateY: 0,
										itemsSpacing: 2,
										itemWidth: 100,
										itemHeight: 20,
										itemDirection: 'left-to-right',
										symbolSize: 20
									}
								]}
								data={topSellingProductsInTimeRange.map(product => ({
									productId: product.id,
									value: product.totalQuantity,
									[productMap[product.id].name]: product.totalQuantity
								}))}
								indexBy='productId'
								margin={{ top: 10, right: 400, bottom: 5, left: 60 }}
								padding={0.3}
								axisTop={null}
								axisRight={null}
								enableLabel={false}
								axisBottom={null}
								axisLeft={{
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: 'Total Quantity',
									legendPosition: 'middle',
									legendOffset: -55
								}}
								theme={{
									axis: {
										legend: {
											text: {
												fill: '#64748B'
											}
										},
										fontSize: '12px'
									}
								}}
								tooltipLabel={(data) => `${productMap[data.indexValue].name}`}
								tooltip={data => {
									const product = productMap[data.indexValue]
									const weightSpecification = product.productSpecifications.find(spec => spec.specificationKey === 'weight')
									const volumeSpecification = product.productSpecifications.find(spec => spec.specificationKey === 'volume')
									return (
										<div
											style={{
												background: 'black',
												color: 'white',
												padding: '4px 8px'
											}}
										>
											{
												weightSpecification &&
												<div className={styles.productWeight} style={{ color: 'white' }}>
													{`Weight: ${weightSpecification.value} ${weightSpecification.unit}`}
												</div>
											}
											{
												volumeSpecification &&
												<div className={styles.productWeight} style={{ color: 'white' }}>
													{`Volume: ${volumeSpecification.value} ${volumeSpecification.unit}`}
												</div>
											}
											<div className={styles.productWeight} style={{ color: 'white' }}>
												{`Price: ${getFormattedCurrency(product.price)}`}
											</div>
											<span className={styles.productWeight}
												style={{
													display: 'flex',
													alignItems: 'center',
													color: 'white'
												}}
											>
												Total Sales Quantity:
												<div className={styles.productPrice} style={{ marginLeft: 4, color: 'white' }}>
													{product.totalQuantity}
												</div>
											</span>
											<span className={styles.productWeight} style={{ display: 'flex', color: 'white' }}>
												Total Sales Amount:
											</span>
											<div className={styles.productPrice} style={{ color: 'white' }}>
												{getFormattedCurrency(product.totalSalesAmount)}
											</div>
										</div>
									)
								}}
								labelSkipHeight={12}
								role='application'
								barAriaLabel={e => {
									return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
								}}
							/> :
							<CustomEmptySecondary style={{ marginTop: 0 }} />
					}
				</div> :
				<div style={{ position: 'relative' }}>
					<div className={styles.productsContainer} ref={productsRef}>
						{
							isLoadingProducts ?
								<div className={styles.productItemSpinnerContainer}>
									<Spin />
								</div> :
								topSellingProductsInTimeRange.length > 0 ?
									topSellingProductsInTimeRange.map(item => {
										const weightSpecification = item.productSpecifications.find(spec => spec.specificationKey === 'weight')
										const volumeSpecification = item.productSpecifications.find(spec => spec.specificationKey === 'volume')
										return (
											renderProductCard(item,	weightSpecification, volumeSpecification)
										)
									}) :
									<CustomEmptySecondary />
						}
					</div>
					{
						topSellingProductsInTimeRange.length > 0 &&
						<Button
							className={`${styles.slideButton} ${styles.slideButtonLeft}`}
							icon={<LeftOutlined />}
							onClick={() => sideScroll(productsRef.current, 50, 200, -20)}
						/>
					}
					{
						topSellingProductsInTimeRange.length > 0 &&
						<Button
							className={`${styles.slideButton} ${styles.slideButtonRight}`}
							icon={<RightOutlined />}
							onClick={() => sideScroll(productsRef.current, 50, 200, 20)}
						/>
					}
				</div>
		)
	}

	const renderProductCard = (item, weightSpecification, volumeSpecification) => {
		return (
			<div
				key={item.id}
				className={styles.productItemContainer}
				onClick={() => router.push(`products/${item.id}`)}
			>
				<div className={styles.productImageContainer}>
					{
						item.imageUrls && item.imageUrls[0] ?
							<img
								className={styles.productImage}
								placeholder={true}
								src={item.imageUrls[0]}
								referrerpolicy='no-referrer'
							/> :
							<Skeleton.Image style={{ width: 176, height: 176 }} />
					}
				</div>
				<div className={styles.productName}>{item.name}</div>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{
						weightSpecification &&
						<div className={styles.productWeight}>
							{`Weight: ${weightSpecification.value} ${weightSpecification.unit}`}
						</div>
					}
					{
						volumeSpecification &&
							<div className={styles.productWeight}>
								{`Volume: ${volumeSpecification.value} ${volumeSpecification.unit}`}
							</div>
					}
					<div className={styles.productWeight}>
						{`Price: ${getFormattedCurrency(item.price)}`}
					</div>
				</div>
				<span className={styles.productWeight} style={{ display: 'flex', alignItems: 'center' }}>
					Total Sales Quantity:
					<div className={styles.productPrice} style={{ marginLeft: 4 }}>
						{item.totalQuantity}
					</div>
				</span>
				<span className={styles.productWeight} style={{ display: 'flex' }}>
					Total Sales Amount:
				</span>
				<div className={styles.productPrice}>
					{getFormattedCurrency(item.totalSalesAmount)}
				</div>
			</div>
		)
	}

	return (
		<>
			<div style={{ display: 'flex' }}>
				<div>
					<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
						<h2 style={{ alignSelf: 'center', marginBottom: '0px' }} className={styles.subTitle}>
							Top 10 Selling Items
							<Tooltip
								title='Top 10 selling items by various criteria based on a time period'
								placement='top'
							>
								<img src='/img/info.svg' alt='Alert icon' />
							</Tooltip>
						</h2>
						<Button
							style={{ border: 'none' }}
							icon={<ReloadOutlined />}
							onClick={getTopSellingProducts}
							loading={isLoadingProducts}
						/>
					</div>
				</div>
				<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
					{
						topProductsDateRange.timePeriod.value === timeSpan.custom &&
								<div style={{ display: 'flex', marginRight: 12 }}>
									{renderDatePicker(topProductsDateRange, setTopProductsDateRange)}
								</div>
					}
					<Dropdown
						overlayClassName={styles.overlay}
						trigger={['click']}
						overlay={() => {
							return (
								<Menu>
									{
										productStatuses.map(status => {
											return (
												<Menu.Item
													key={status.key}
													onClick={() => setTopProductsStatus(status)}
												>
													{status.label}
												</Menu.Item>
											)
										})
									}
								</Menu>
							)
						}}
					>
						<div className={styles.timeTabSelected}>
							{topProductsStatus.label}
							<DownOutlined style={{ marginLeft: 12 }} />
						</div>
					</Dropdown>
					<Dropdown
						overlayClassName={styles.overlay}
						trigger={['click']}
						overlay={() => {
							return (
								<Menu>
									{
										timePeriods.map(orderPeriod => {
											return (
												<Menu.Item
													key={orderPeriod.key}
													onClick={() => {
														onTimePeriodSelected(
															orderPeriod,
															setTopProductsDateRange
														)
													}}
												>
													{orderPeriod.label}
												</Menu.Item>
											)
										})
									}
								</Menu>
							)
						}}
					>
						<div className={styles.timeTabSelected}>
							{topProductsDateRange.timePeriod.label}
							<DownOutlined style={{ marginLeft: 12 }} />
						</div>
					</Dropdown>
					<div className={styles.productViewButton} style={{ width: 32, height: 32 }}>
						{
							isDownloadingProducts ?
								<Spin /> :
								<img
									src='/img/download-green.svg'
									onClick={() => downloadTopSellingProductsCsv()}
								/>
						}
					</div>
					<img
						src={showGraphView ? '/img/image-view.svg' : '/img/graph-view.svg' }
						className={styles.productViewButton}
						onClick={() => {
							const showGraph = !showGraphView
							setShowGraphView(showGraph)
							if (showGraph) {
								getTopSellingProducts()
							}
						}}
					/>
				</div>

			</div>
			<div style={{ marginBottom: 24 }}>
				{renderDateRange(topProductsDateRange)}
			</div>
			{renderTopProducts()}
		</>
	)

}

TopSellingItems.propTypes = {
	isLoadingSalesAnalyticsOrderTrends: PropTypes.bool.isRequired
}

export default TopSellingItems
